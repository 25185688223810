import React, { useEffect, useState,useRef } from 'react';
import BackgroundMusic from './circus_music';

function HeroSection() {
  const contractAddress = "DTZ8utxJhFjeQGai3tAdvTLx6efikyWiygqVq6cTun57";
  const dex =`https://raydium.io/swap/?inputMint=${contractAddress}&outputMint=sol`;
  const [emojiCount, setEmojiCount] = useState(50); // Initial emoji count


  const musicRef = useRef(null); // Reference for BackgroundMusic component
  
  // Create a function to generate random number within a range
  const getRandomValue = (min, max) => Math.random() * (max - min) + min;

  useEffect(() => {
    const emojiContainer = document.getElementById('emoji-container');
    const animations = [
      'randomMove1',
      'randomMove2',
      'randomMove3',
      'randomMove4'
    ];

    // Create new emojis and add them to the container
    for (let i = 0; i < emojiCount; i++) {
      const emoji = document.createElement('div');
      emoji.classList.add('emoji');
      emoji.textContent = '🤡';

      const randomTop = getRandomValue(0, 100); 
      const randomLeft = getRandomValue(0, 100);
      const randomDuration = getRandomValue(8, 15);

      const randomAnimation = animations[Math.floor(Math.random() * animations.length)];

      emoji.style.top = `${randomTop}%`;
      emoji.style.left = `${randomLeft}%`;
      emoji.style.animationDuration = `${randomDuration}s`;
      emoji.style.animationName = randomAnimation;

      emojiContainer.appendChild(emoji);
    }
  }, [emojiCount]); // Re-run the effect when emojiCount changes

  const increaseEmojiCount = () => {
    setEmojiCount(emojiCount + 5); // Increase count by 5 each time FAB is clicked
  musicRef.current?.playSound(); 
  };


  return (
    <section className="hero">
      <div className="hero-content">
      <img 
          src="/assets/clown-ear-min.png" 
          alt="Clown" 
          className="clown-image-hero"
        />
        <h2>$CLOWN</h2>
        <BackgroundMusic ref={musicRef} />
        
        <a href={dex} className="buy-button">GET $CLOWN NOW!</a>
      </div>

      {/* Emoji container */}
      <div id="emoji-container" />
      
      {/* Floating Action Button */}
{/* Floating Action Button */}
<button className="fab" onClick={increaseEmojiCount}>
  <img src="/assets/horn.png" alt="Increase Emoji Count" className="fab-image" />
</button>

    </section>
  );
}

export default HeroSection;
