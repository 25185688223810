import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <p>Don't miss out, CLOWN ON SOL.</p>
      <p>© 2024. All Rights Reserved. $CLOWN, Soon to be Community Takeover</p>     
    </footer>
  );
}
export default Footer;
